<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-06-23 11:33:20
 * @ Description: Fixer's home page in the user's dashboard section of the app.
 -->

<template>
    <div
        style="padding-bottom: 84px;"
    >
        <v-row
            justify="center"
            id="newsPostContainer"
        >
            <!-- Desktop -->
            <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
                cols="12"
            >
                <!-- Dashboard -->
                <v-row>
                    <v-col
                        cols="8"
                        class="px-0 pb-0"
                    >
                        <!-- Welcome -->
                        <v-col
                            cols="12"
                            class="os-22-r"
                            style="line-height: 2;"
                        >
                            Welcome, {{ name }}!
                            <div
                                class="os-13-r"
                            >
                                You are on the <span class="os-13-sb">{{ subscription }}</span> package. <span @click="$router.push(`/profile/account`)" class="os-13-sb" style="cursor: pointer; text-decoration: underline;">Upgrade today</span> for added benefits. <span @click="$router.push(`/profile/account`)" style="cursor: pointer; color: #0095FF;">Learn More</span>
                            </div>
                        </v-col>
                        <!-- Welcome -->

                        <!-- Cards -->
                        <v-col
                            cols="12"
                        >
                            <v-row>
                                <!-- Find Jobs/Talent Button -->
                                <v-col
                                    cols="4"
                                    class="text-center"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg rob-18-m"
                                        color="#2E567A"
                                        style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                        @click="$router.push('/job/search')"
                                        v-if="canSearchJobs"
                                        :style="$vuetify.breakpoint.md ? 'width: 200px; max-width: 200px;' : $vuetify.breakpoint.lg ? 'width: 220px; max-width: 220px;' : 'width: 280px; max-width: 280px;'"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="#FFA858"
                                            large
                                        >
                                            {{ mdiFlashOutline }}
                                        </v-icon>
                                        Find Jobs
                                    </v-btn>

                                    <v-btn
                                        class="white--text no-uppercase rounded-lg rob-18-m"
                                        color="#2E567A"
                                        style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                        @click="$router.push('/job/all')"
                                        v-else
                                        :style="$vuetify.breakpoint.md ? 'width: 200px; max-width: 200px;' : $vuetify.breakpoint.lg ? 'width: 220px; max-width: 220px;' : 'width: 280px; max-width: 280px;'"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="#FFA858"
                                            large
                                        >
                                            {{ mdiFlashOutline }}
                                        </v-icon>
                                        Find Talent
                                    </v-btn>
                                </v-col>
                                <!-- Find Jobs/Talent Button -->

                                <!-- Find Services Button -->
                                <v-col
                                    cols="4"
                                    class="text-center"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg rob-18-m"
                                        color="#2E567A"
                                        style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                        @click="$router.push('/service/search')"
                                        :style="$vuetify.breakpoint.md ? 'width: 200px; max-width: 200px;' : $vuetify.breakpoint.lg ? 'width: 220px; max-width: 220px;' : 'width: 280px; max-width: 280px;'"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="#FFA858"
                                            large
                                        >
                                            {{ mdiAccountSupervisorOutline }}
                                        </v-icon>
                                        Find Services
                                    </v-btn>
                                </v-col>
                                <!-- Find Services Button -->

                                <!-- Add Services Button -->
                                <v-col
                                    cols="4"
                                    class="text-center"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg rob-18-m"
                                        color="#2E567A"
                                        style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                        @click="$router.push('/profile/services')"
                                        :style="$vuetify.breakpoint.md ? 'width: 200px; max-width: 200px;' : $vuetify.breakpoint.lg ? 'width: 220px; max-width: 220px;' : 'width: 280px; max-width: 280px;'"
                                    >
                                        <v-icon
                                            class="mr-1"
                                            color="#FFA858"
                                            large
                                        >
                                            {{ mdiPlusCircleOutline }}
                                        </v-icon>
                                        Add Services
                                    </v-btn>
                                </v-col>
                                <!-- Add Services Button -->

                                <!-- Amount Eardned -->
                                <v-col
                                    cols="4"
                                    class="pb-0"
                                >
                                    <v-card
                                        flat
                                        class="rounded-lg mx-auto"
                                        style="border: 1px solid #EFF4FA;"
                                        :width="$vuetify.breakpoint.md ? '200px' : $vuetify.breakpoint.lg ? '220px' : '280px'"
                                        :max-width="$vuetify.breakpoint.md ? '200px' : $vuetify.breakpoint.lg ? '220px' : '280px'"
                                        height="190px"
                                        max-height="190px"
                                    >
                                        <v-card-text
                                            style="height: 100%;"
                                            class="pt-9"
                                        >
                                            <v-row
                                                style="height: 100%;"
                                            >
                                                <v-col
                                                    cols="12"
                                                    class="text-center os-13-r"
                                                    style="color: #8F9BB3; "
                                                    align-self="center"
                                                >
                                                    <div
                                                        style="position: relative;"
                                                    >
                                                        Amount Earned
                                                        <v-icon
                                                            style="position: absolute;"
                                                            :style="$vuetify.breakpoint.md ? 'top: 4px; left: 8px;' : $vuetify.breakpoint.lg ? 'top: 4px; left: 16px;' : 'top: 4px; left: 24px;'"
                                                            color="#8F9BB3"
                                                            :size="$vuetify.breakpoint.md ? 12 : $vuetify.breakpoint.lg ? 14 : 20"
                                                        >
                                                            {{ mdiCurrencyUsd }}
                                                        </v-icon>
                                                        <v-icon
                                                            style="position: absolute;"
                                                            :style="$vuetify.breakpoint.md ? 'top: 0px; left: 4px;' : $vuetify.breakpoint.lg ? 'top: 0px; left: 12px;' : 'top: 0px; left: 20px;'"
                                                            color="#8F9BB3"
                                                            :size="$vuetify.breakpoint.md ? 20 : $vuetify.breakpoint.lg ? 22 : 28"
                                                        >
                                                            {{ mdiCircleOutline }}
                                                        </v-icon>
                                                    </div>
                                                    <div
                                                        style="color: #44D7B6;"
                                                        class="os-20-b "
                                                    >
                                                        {{ stats.symbol }}{{ Number(stats.amount).toFixed() }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <!-- Amount Eardned -->

                                <!-- Active Work Items -->
                                <v-col
                                    cols="4"
                                    class="pb-0"
                                >
                                    <v-card
                                        flat
                                        class="rounded-lg mx-auto"
                                        style="border: 1px solid #EFF4FA;"
                                        :width="$vuetify.breakpoint.md ? '200px' : $vuetify.breakpoint.lg ? '220px' : '280px'"
                                        :max-width="$vuetify.breakpoint.md ? '200px' : $vuetify.breakpoint.lg ? '220px' : '280px'"
                                        height="190px"
                                        max-height="190px"
                                    >
                                        <v-card-text
                                            style="height: 100%;"
                                            class="pt-9"
                                        >
                                            <v-row
                                                style="height: 100%;"
                                            >
                                                <v-col
                                                    cols="12"
                                                    class="text-center os-13-r"
                                                    style="color: #8F9BB3;"
                                                    align-self="center"
                                                >
                                                    <div
                                                        style="margin-bottom: 10%; color: #2E567A;"
                                                        class="os-17-r"
                                                    >
                                                        <v-icon
                                                            color="#2E567A"
                                                            size="24"
                                                            class="mr-1 mb-1"
                                                        >
                                                            {{ mdiFlashOutline }}
                                                        </v-icon>
                                                        Jobs
                                                    </div>
                                                    <div>
                                                        Active
                                                    </div>
                                                    <div
                                                        class="os-20-b"
                                                        style="color: #2E567A;"
                                                    >
                                                        {{ stats.job.active }}
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <!-- Active Work Items -->

                                <!-- Assigned Work -->
                                <v-col
                                    cols="4"
                                    class="pb-0"
                                >
                                    <v-card
                                        flat
                                        class="rounded-lg mx-auto"
                                        style="border: 1px solid #EFF4FA;"
                                        :width="$vuetify.breakpoint.md ? '200px' : $vuetify.breakpoint.lg ? '220px' : '280px'"
                                        :max-width="$vuetify.breakpoint.md ? '200px' : $vuetify.breakpoint.lg ? '220px' : '280px'"
                                        height="190px"
                                        max-height="190px"
                                    >
                                        <v-card-text
                                            style="height: 100%;"
                                            class="pt-9"
                                        >
                                            <v-row
                                                style="height: 100%;"
                                            >
                                                <v-col
                                                    cols="12"
                                                    class="text-center os-13-r"
                                                    style="color: #8F9BB3;"
                                                    align-self="center"
                                                >
                                                    <div
                                                        style="margin-bottom: 15%; color: #2E567A;"
                                                        class="os-17-r"
                                                    >
                                                        <v-icon
                                                            color="#2E567A"
                                                            size="24"
                                                            class="mb-1 mr-1"
                                                        >
                                                            {{ mdiAccountSupervisorOutline }}
                                                        </v-icon>
                                                        Professional<br />Services
                                                    </div>
                                                    <div
                                                        style="display: flex; align-items: center;"
                                                    >
                                                        <div
                                                            style="width: 50%; max-width: 50%; border-right: #8F9BB3 solid 1px;"
                                                        >
                                                            <div>
                                                                Requested
                                                            </div>
                                                            <div
                                                                class="os-20-b"
                                                                style="color: #2E567A;"
                                                            >
                                                                {{ stats.service.requested }}
                                                            </div>
                                                        </div>
                                                        <div
                                                            style="width: 50%; max-width: 50%;"
                                                        >
                                                            <div>
                                                                Provided
                                                            </div>
                                                            <div
                                                                class="os-20-b"
                                                                style="color: #2E567A;"
                                                            >
                                                                {{ stats.service.provided }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                                <!-- Assigned Work -->
                            </v-row>
                        </v-col>
                        <!-- Cards -->
                    </v-col>

                    <!-- Your Schedule -->
                    <v-col
                        cols="4"
                    >
                        <general-home-your-schedule-component />
                    </v-col>
                    <!-- Your Schedule -->
                </v-row>
                <!-- Dashboard -->

                <!-- Active Tasks -->
                <v-row>
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <div
                            class="os-17-sb px-3"
                            style="background-color: white; height: 60px; display: flex; align-items: center;"
                        >
                            Active Tasks
                            <v-spacer />
                            <div
                                style="width: 275px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="activeTaskSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        class="pt-0"
                    >
                        <v-data-table
                            :headers="tableHeaders"
                            :items="activeTasks"
                            item-key="id"
                            hide-default-header
                            hide-default-footer
                            disable-pagination
                            :no-data-text="'No active tasks'"
                            :no-results-text="noResultsText"
                            :search="activeTaskSearch"
                        >
                            <template
                                v-slot:header="{ props: { headers } }"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            v-for="(item, index) in headers"
                                            :key="index"
                                        >
                                            <div
                                                style="display: flex; align-items: center;"
                                            >
                                                <v-icon
                                                    v-if="item.value.includes('date')"
                                                    color="#2E567A"
                                                    style="opacity: 0.65;"
                                                    class="mr-1"
                                                >
                                                    {{ mdiCalendarOutline }}
                                                </v-icon>
                                                {{ item.text }}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                {{ item.name }}
                            </template>
                            <template v-slot:[`item.milestone`]="{ item }">
                                <span v-if="item.milestone">{{ item.milestone }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No milestones
                                </span>
                            </template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                <span v-if="item.start_date">{{ item.start_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No start date
                                </span>
                            </template>
                            <template v-slot:[`item.end_date`]="{ item }">
                                <span v-if="item.end_date">{{ item.end_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No end date
                                </span>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-icon
                                    x-small
                                    style="margin-right: 1px;"
                                    :style="item.status.stage_id === 1 ? 'color: #707070;' : item.status.stage_id === 2 ? 'color: #FFA858;' : item.status.stage_id === 3 ? 'color: #08D500;' : item.status.id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'"
                                >
                                    {{ mdiCheckboxBlankCircle }}
                                </v-icon>
                                {{ item.status.stage_name }}
                            </template>
                            <template v-slot:[`item.type`]="{ item }">
                                <v-icon
                                    color="#2E567A"
                                    size="20"
                                    class="mr-1"
                                    v-if="item.type === 'Job'"
                                >
                                    {{ mdiFlashOutline }}
                                </v-icon>
                                <v-icon
                                    color="#2E567A"
                                    size="20"
                                    class="mr-1"
                                    v-else-if="item.type === 'Service'"
                                >
                                    {{ mdiAccountSupervisorOutline }}
                                </v-icon>
                                <!-- <v-icon
                                    color="#2E567A"
                                    size="20"
                                    class="mr-1"
                                    v-else-if="item.type.name === 'Project'"
                                >
                                    {{ mdiPencilOutline }}
                                </v-icon>
                                <v-icon
                                    color="#2E567A"
                                    size="20"
                                    class="mr-1"
                                    v-else-if="item.type.name === 'Tender'"
                                >
                                    {{ mdiLicense }}
                                </v-icon> -->
                                {{ item.type }}
                            </template>
                            <template v-slot:[`item.action`]="{ item }">
                                <v-btn
                                    icon
                                    @click="viewTask(item)"
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiArrowRightThinCircleOutline }}
                                    </v-icon>
                                </v-btn>
                                <!-- <v-btn
                                    icon
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiPrinterOutline }}
                                    </v-icon>
                                </v-btn> -->
                                <!-- <v-btn
                                    icon
                                >
                                    <v-icon
                                        color="#2E567A"
                                        style="opacity: 0.65;"
                                        size="20"
                                    >
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                </v-btn> -->
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <!-- Active Tasks -->

                <!-- News Feed -->
                <v-row
                    class="pb-6"
                >
                    <!-- Heading & Search -->
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <div
                            class="os-17-sb px-3"
                            style="height: 60px; display: flex; align-items: center;"
                        >
                            Fixer News
                            <!-- <v-spacer />
                            <div
                                style="width: 275px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="newsArticleSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </div> -->
                        </div>
                    </v-col>
                    <!-- Heading & Search -->

                    <!-- News Feed Cards -->
                    <v-col
                        v-if="newsPosts === null || newsPosts.length === 0"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No news posts
                    </v-col>

                    <v-col
                        v-for="(item, index) in newsPosts"
                        :key="index"
                        cols="12"
                        v-else
                    >
                        <v-card
                            class="rounded-lg mx-auto"
                            style="border: 1px solid #EFF4FA;"
                        >
                            <v-card-text
                                style="color: black; padding: 11px;"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="pa-0"
                                        style="position: relative;"
                                    >
                                        <div
                                            class="pa-3"
                                            style="min-height: 125px; display: flex; align-items: center;"
                                        >
                                            <v-img
                                                :src="item.image_url ? item.image_url : '/branding/FixerLogoBlue.png'"
                                                max-height="150px"
                                                max-width="150px"
                                                :height="$vuetify.breakpoint.mdAndUp && item.image_url ? '100px' : '75px'"
                                                :width="$vuetify.breakpoint.mdAndUp && item.image_url ? '100px' : '75px'"
                                                contain
                                                class="mx-auto"
                                            />
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="os-16-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ item.title }}
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0"
                                    >
                                        <v-divider />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="pb-0"
                                        style="color: #2E567A;"
                                        v-html="item.description"
                                    />
                                    <!-- <v-col
                                        class="text-right pt-0"
                                        cols="12"
                                        style="line-height: 1.5;"
                                    >
                                        <div
                                            class="os-10-r"
                                            style="color: #8F9BB3;"
                                        >
                                            Expires:
                                        </div>
                                        <div
                                            class="os-12-sb"
                                            style="color: #2E567A;"
                                        >
                                            <span v-if="item.expiration_date">{{ item.expiration_date }}</span>
                                            <span
                                                v-else
                                                class="os-10-r"
                                                style="color: #8F9BB3;"
                                            >
                                                No expiration
                                            </span>
                                        </div>
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                <!-- News Feed Cards -->
                </v-row>
                <!-- News Feed -->
            </v-col>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-col
                v-if="$vuetify.breakpoint.smAndDown"
                cols="12"
            >
                <v-row
                    style="background-color: rgba(255, 168, 88, 0.1);"
                    justify="center"
                    class="pb-3"
                >
                    <!-- Dashboard -->
                    <v-col
                        cols="12"
                        class="px-0"
                        :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
                    >
                        <v-row
                            class="mx-3"
                            justify="center"
                        >
                            <!-- Welcome -->
                            <v-col
                                cols="12"
                                class="os-22-r text-center px-1"
                                style="line-height: 2;"
                            >
                                <div>Welcome, {{ name }}!</div>
                                <div
                                    class="os-13-r"
                                    style="line-height: 1.5;"
                                >
                                    You are on the <span class="os-13-sb">{{ subscription }}</span> package.
                                    <br />
                                    <span @click="$router.push(`/profile/account`)" class="os-13-sb" style="cursor: pointer; text-decoration: underline;">Upgrade today</span> for added benefits.
                                    <br />
                                    <br />
                                    <span @click="$router.push(`/profile/account`)" style="cursor: pointer; color: #0095FF;">Learn More</span>
                                </div>
                            </v-col>
                            <!-- Welcome -->

                            <!-- Find Jobs/Talent Button -->
                            <v-col
                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                class="text-center pt-0"
                                :class="$store.state.general.customBreakpoints.xxs ? 'px-2' : 'pr-1'"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg rob-16-m"
                                    color="#2E567A"
                                    style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                    @click="$router.push('/job/search')"
                                    v-if="canSearchJobs"
                                    block
                                >
                                    <v-icon
                                        class="mr-1"
                                        large
                                        color="#FFA858"
                                    >
                                        {{ mdiFlashOutline }}
                                    </v-icon>
                                    Find Jobs
                                </v-btn>

                                <v-btn
                                    class="white--text no-uppercase rounded-lg rob-16-m"
                                    color="#2E567A"
                                    style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                    @click="$router.push('/job/all')"
                                    v-else
                                    block
                                >
                                    <v-icon
                                        class="mr-1"
                                        large
                                        color="#FFA858"
                                    >
                                        {{ mdiFlashOutline }}
                                    </v-icon>
                                    Find Talent
                                </v-btn>
                            </v-col>
                            <!-- Find Jobs/Talent Button -->

                            <!-- Find Services Button -->
                            <v-col
                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                class="text-center pt-0"
                                :class="$store.state.general.customBreakpoints.xxs ? 'px-2' : 'pl-1'"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg rob-16-m"
                                    color="#2E567A"
                                    style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                    @click="$router.push('/service/search')"
                                    block
                                >
                                    <v-icon
                                        class="mr-1"
                                        large
                                        color="#FFA858"
                                    >
                                        {{ mdiAccountSupervisorOutline }}
                                    </v-icon>
                                    Find Services
                                </v-btn>
                            </v-col>
                            <!-- Find Services Button -->

                            <!-- Add Services Button -->
                            <v-col
                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : 6"
                                class="text-center pt-0 px-2"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg rob-16-m"
                                    color="#2E567A"
                                    style="height: 58px; box-shadow: 6px 6px 8px #8F9BB3; border: solid 2px #FFA858;"
                                    @click="$router.push('/profile/services')"
                                    block
                                >
                                    <v-icon
                                        class="mr-1"
                                        large
                                        color="#FFA858"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                    Add Services
                                </v-btn>
                            </v-col>
                            <!-- Add Services Button -->

                            <!-- Cards -->
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-row>
                                    <!-- Amount Eardned -->
                                    <v-col
                                        cols="12"
                                        class="pb-0"
                                    >
                                        <v-card
                                            flat
                                            class="rounded-lg mx-auto"
                                            style="border: 1px solid #EFF4FA;"
                                        >
                                            <v-card-text
                                                style="height: 100%;"
                                            >
                                                <v-row
                                                    style="height: 100%;"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="text-center os-13-r"
                                                        style="color: #8F9BB3; height: 65px; margin-top: 24px;"
                                                        align-self="center"
                                                    >
                                                        <div>
                                                            <v-icon
                                                                style="position: absolute; top: 12px; left: 0; right: 0; margin-left: auto; margin-right: auto;"
                                                                color="#44D7B6"
                                                                size="20"
                                                            >
                                                                {{ mdiCurrencyUsd }}
                                                            </v-icon>
                                                            <v-icon
                                                                style="position: absolute; top: 12px; left: 0; right: 0; margin-left: auto; margin-right: auto; margin-top: -4px;"
                                                                color="#44D7B6"
                                                                size="28"
                                                            >
                                                                {{ mdiCircleOutline }}
                                                            </v-icon>
                                                        </div>
                                                        <div>
                                                            Amount Earned
                                                        </div>
                                                        <div
                                                            style="color: #44D7B6;"
                                                            class="os-20-b "
                                                        >
                                                            {{ stats.symbol }}{{ Number(stats.amount).toFixed() }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <!-- Amount Eardned -->

                                    <!-- Active Work Items -->
                                    <v-col
                                        cols="6"
                                        class="pb-0 pr-1"
                                    >
                                        <v-card
                                            flat
                                            class="rounded-lg mx-auto"
                                            style="border: 1px solid #EFF4FA;"
                                            height="168px"
                                            max-height="168px"
                                        >
                                            <v-card-text
                                                style="height: 100%;"
                                                class="pt-7"
                                            >
                                                <v-row
                                                    style="height: 100%;"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="text-center os-10-r"
                                                        style="color: #8F9BB3;"
                                                        align-self="center"
                                                    >
                                                        <div
                                                            style="margin-bottom: 10%; color: #2E567A;"
                                                            class="os-15-r"
                                                        >
                                                            <v-icon
                                                                color="#2E567A"
                                                                size="24"
                                                                class="mb-1"
                                                            >
                                                                {{ mdiFlashOutline }}
                                                            </v-icon>
                                                            Jobs
                                                        </div>
                                                        <div>
                                                            Active
                                                        </div>
                                                        <div
                                                            class="os-20-b"
                                                            style="color: #2E567A;"
                                                        >
                                                            {{ stats.job.active }}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <!-- Active Work Items -->

                                    <!-- Assigned Work -->
                                    <v-col
                                        cols="6"
                                        class="pb-0 pl-1"
                                    >
                                        <v-card
                                            flat
                                            class="rounded-lg mx-auto"
                                            style="border: 1px solid #EFF4FA;"
                                            height="168px"
                                            max-height="168px"
                                        >
                                            <v-card-text
                                                style="height: 100%;"
                                                class="pt-7 px-1"
                                            >
                                                <v-row
                                                    style="height: 100%;"
                                                >
                                                    <v-col
                                                        cols="12"
                                                        class="text-center os-10-r px-1"
                                                        style="color: #8F9BB3;"
                                                        align-self="center"
                                                    >
                                                        <div
                                                            style="margin-bottom: 15%; color: #2E567A;"
                                                            class="os-15-r"
                                                        >
                                                            <v-icon
                                                                color="#2E567A"
                                                                size="24"
                                                                class="mb-1"
                                                            >
                                                                {{ mdiAccountSupervisorOutline }}
                                                            </v-icon>
                                                            Professional<br />Services
                                                        </div>
                                                        <div
                                                            style="display: flex; align-items: center;"
                                                        >
                                                            <div
                                                                style="width: 50%; max-width: 50%; border-right: #8F9BB3 solid 1px;"
                                                            >
                                                                <div>
                                                                    Requested
                                                                </div>
                                                                <div
                                                                    class="os-20-b"
                                                                    style="color: #2E567A;"
                                                                >
                                                                    {{ stats.service.requested }}
                                                                </div>
                                                            </div>
                                                            <div
                                                                style="width: 50%; max-width: 50%;"
                                                            >
                                                                <div>
                                                                    Provided
                                                                </div>
                                                                <div
                                                                    class="os-20-b"
                                                                    style="color: #2E567A;"
                                                                >
                                                                    {{ stats.service.provided }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <!-- Assigned Work -->
                                </v-row>
                            </v-col>
                            <!-- Cards -->
                        </v-row>
                    </v-col>
                    <!-- Dashboard -->

                    <!-- Your Schedule -->
                    <v-col
                        class="pa-6 pb-3"
                        cols="12"
                        :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
                    >
                        <general-home-your-schedule-component />
                    </v-col>
                    <!-- Your Schedule -->
                </v-row>

                <!-- Active Tasks -->
                <v-row
                    justify="center"
                >
                    <v-col
                        cols="12"
                        :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
                    >
                        <v-row
                            style="background-color: white;"
                            :style="$store.state.general.customBreakpoints.xxs ? '' : 'height: 60px;'"
                            align="center"
                            class="mx-0 mt-3"
                        >
                            <v-col
                                class="os-17-sb px-3"
                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : $vuetify.breakpoint.xs ? 6 : ''"
                            >
                                Active Tasks
                            </v-col>
                            <v-col
                                class="text-right"
                                :cols="$store.state.general.customBreakpoints.xxs ? 12 : $vuetify.breakpoint.xs ? 6 : ''"
                                :style="$vuetify.breakpoint.sm ? 'max-width: 300px;' : ''"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    style="max-width: 275px;"
                                    v-model="activeTaskSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col
                        cols="12"
                        class="pt-0"
                        :style="$store.state.general.customBreakpoints.xxs ? 'max-width: 280px;' : $vuetify.breakpoint.xs ? 'max-width: 360px;' : 'max-width: 500px'"
                    >
                        <v-data-table
                            :headers="$store.state.general.customBreakpoints.xxs ? xxsMobileTableHeaders : mobileTableHeaders"
                            :items="activeTasks"
                            item-key="id"
                            hide-default-header
                            hide-default-footer
                            disable-pagination
                            mobile-breakpoint="0"
                            class="mobile-table"
                            :no-data-text="'No active tasks'"
                            :no-results-text="noResultsText"
                            :search="activeTaskSearch"
                        >
                            <template
                                v-slot:header="{ props: { headers } }"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            v-for="(item, index) in headers"
                                            :key="index"
                                        >
                                            <div
                                                style="display: flex; align-items: center;"
                                            >
                                                <v-icon
                                                    v-if="item.value.includes('date')"
                                                    color="#2E567A"
                                                    style="opacity: 0.65;"
                                                    class="mr-1"
                                                >
                                                    {{ mdiCalendarOutline }}
                                                </v-icon>
                                                {{ item.text }}
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <v-row>
                                    <v-col
                                        class="flex-shrink-1 flex-grow-0 pr-0"
                                    >
                                        <v-icon
                                            color="#2E567A"
                                            size="20"
                                            class="mr-1"
                                            v-if="item.type === 'Job'"
                                        >
                                            {{ mdiFlashOutline }}
                                        </v-icon>
                                        <v-icon
                                            color="#2E567A"
                                            size="20"
                                            class="mr-1"
                                            v-else-if="item.type === 'Service'"
                                        >
                                            {{ mdiAccountSupervisorOutline }}
                                        </v-icon>
                                        <!-- <v-icon
                                            color="#2E567A"
                                            size="20"
                                            class="mr-1"
                                            v-else-if="item.type.name === 'Project'"
                                        >
                                            {{ mdiPencilOutline }}
                                        </v-icon>
                                        <v-icon
                                            color="#2E567A"
                                            size="20"
                                            class="mr-1"
                                            v-else-if="item.type.name === 'Tender'"
                                        >
                                            {{ mdiLicense }}
                                        </v-icon> -->
                                    </v-col>
                                    <v-col
                                        class="flex-grow-1 flex-shrink-0 pa-0"
                                        align-self="center"
                                    >
                                        {{ item.name }}
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:[`item.start_date`]="{ item }">
                                <span v-if="item.start_date">{{ item.start_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No date
                                </span>
                            </template>
                            <template v-slot:[`item.end_date`]="{ item }">
                                <span v-if="item.end_date">{{ item.end_date }}</span>
                                <span
                                    class="os-12-r"
                                    style="color: rgba(0, 0, 0, 0.38);"
                                    v-else
                                >
                                    No date
                                </span>
                            </template>
                            <template v-slot:[`item.status`]="{ item }">
                                <v-icon
                                    x-small
                                    style="margin-right: 1px;"
                                    :style="item.status.stage_id === 1 ? 'color: #707070;' : item.status.stage_id === 2 ? 'color: #FFA858;' : item.status.stage_id === 3 ? 'color: #08D500;' : item.status.id === 7 ? 'color: #32C5FF;' : 'color: #E02020;'"
                                >
                                    {{ mdiCheckboxBlankCircle }}
                                </v-icon>
                                {{ item.status.stage_name }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <!-- Active Tasks -->

                <!-- News Feed -->
                <v-row
                    class="pb-6"
                >
                    <!-- Heading & Search -->
                    <v-col
                        cols="12"
                        class="py-0"
                    >
                        <div
                            class="os-17-sb px-3"
                            style="height: 60px; display: flex; align-items: center;"
                        >
                            Fixer News
                            <!-- <v-spacer />
                            <div
                                style="width: 275px;"
                            >
                                <v-text-field
                                    dense
                                    hide-details
                                    outlined
                                    color="#2E567A"
                                    placeholder="Search..."
                                    class="os-13-r"
                                    background-color="white"
                                    style="width: 275px;"
                                    v-model="newsArticleSearch"
                                >
                                    <template v-slot:append>
                                        <v-icon
                                            color="#C5CEE0"
                                            class="ma-0"
                                        >
                                            {{ mdiMagnify }}
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </div> -->
                        </div>
                    </v-col>
                    <!-- Heading & Search -->

                    <!-- News Feed Cards -->
                    <v-col
                        v-if="newsPosts === null || newsPosts.length === 0"
                        class="os-12-r text-center"
                        style="color: rgba(0, 0, 0, 0.38);"
                    >
                        No news posts
                    </v-col>

                    <v-col
                        v-for="(item, index) in newsPosts"
                        :key="index"
                        cols="12"
                        v-else
                    >
                        <v-card
                            class="rounded-lg mx-auto"
                            style="border: 1px solid #EFF4FA;"
                        >
                            <v-card-text
                                style="color: black; padding: 11px;"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="pa-0"
                                        style="position: relative;"
                                    >
                                        <div
                                            class="pa-3"
                                            style="min-height: 125px; display: flex; align-items: center;"
                                        >
                                            <v-img
                                                :src="item.image_url ? item.image_url : '/branding/FixerLogoBlue.png'"
                                                max-height="150px"
                                                max-width="150px"
                                                :height="$vuetify.breakpoint.mdAndUp && item.image_url ? '100px' : '75px'"
                                                :width="$vuetify.breakpoint.mdAndUp && item.image_url ? '100px' : '75px'"
                                                contain
                                                class="mx-auto"
                                            />
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="os-16-sb"
                                        style="color: #2E567A;"
                                    >
                                        {{ item.title }}
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="py-0"
                                    >
                                        <v-divider />
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        class="pb-0"
                                        style="color: #2E567A;"
                                        v-html="item.description"
                                    />
                                    <!-- <v-col
                                        class="text-right pt-0"
                                        cols="12"
                                        style="line-height: 1.5;"
                                    >
                                        <div
                                            class="os-10-r"
                                            style="color: #8F9BB3;"
                                        >
                                            Expires:
                                        </div>
                                        <div
                                            class="os-12-sb"
                                            style="color: #2E567A;"
                                        >
                                            <span v-if="item.expiration_date">{{ item.expiration_date }}</span>
                                            <span
                                                v-else
                                                class="os-10-r"
                                                style="color: #8F9BB3;"
                                            >
                                                No expiration
                                            </span>
                                        </div>
                                    </v-col> -->
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                <!-- News Feed Cards -->
                </v-row>
                <!-- News Feed -->
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import GeneralHomeYourScheduleComponent from '@/components/general/home/GeneralHomeYourScheduleComponent.vue'
    import { mdiPlusCircleOutline, mdiHeartOutline, mdiCheckboxBlankCircle, mdiCalendarOutline, mdiMagnify, mdiArrowRightThinCircleOutline, mdiPrinterOutline, mdiTrashCanOutline, mdiFlashOutline, mdiAccountSupervisorOutline, mdiPencilOutline, mdiLicense, mdiCurrencyUsd, mdiCircleOutline } from '@mdi/js'
    // import CountryFlag from 'vue-country-flag'
    import { GeneralHomeController, NewsPostController } from '@/logic/controller/index.controller.js'

    export default {
        name: 'HomePage',

        components: {
            GeneralHomeYourScheduleComponent,
            // CountryFlag
        },

        computed: {
            name () {
                if (this.$store.state.user.userContext === 'company') return this.$store.state.user.userData.company_name
                else return `${this.$store.state.user.userData.first_name} ${this.$store.state.user.userData.last_name}`
            },

            subscription () {
                return this.$store.state.user.userData.subscription_name
            },

            noResultsText () {
                return 'No results found for: "' + this.activeTaskSearch + '"'
            },

            canSearchJobs () {
                if (this.$store.state.user.userData.user_role_id === 2) return false
                else return true
            }
        },

        data () {
            return {
                mdiPlusCircleOutline,
                mdiCurrencyUsd,
                mdiCircleOutline,
                mdiArrowRightThinCircleOutline,
                mdiPrinterOutline,
                mdiTrashCanOutline,
                mdiMagnify,
                mdiCalendarOutline,
                mdiCheckboxBlankCircle,
                mdiFlashOutline,
                mdiAccountSupervisorOutline,
                mdiPencilOutline,
                mdiLicense,
                mdiHeartOutline,
                dashboardItems: [
                    {
                        text: 'Job',
                        icon: mdiFlashOutline
                    },
                    {
                        text: 'Professional Services',
                        icon: mdiAccountSupervisorOutline
                    },
                    {
                        text: 'Project',
                        icon: mdiPencilOutline
                    },
                    {
                        text: 'Tender',
                        icon: mdiLicense
                    }
                ],
                tableHeaders: [
                    {
                        text: 'Task Name',
                        value: 'name'
                    },
                    {
                        text: 'Milestone',
                        value: 'milestone'
                    },
                    {
                        text: 'Start',
                        value: 'start_date'
                    },
                    {
                        text: 'End',
                        value: 'end_date'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    },
                    {
                        text: 'Type',
                        value: 'type'
                    },
                    {
                        text: 'Action',
                        value: 'action'
                    }
                ],
                mobileTableHeaders: [
                    {
                        text: 'Task Name',
                        value: 'name'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    },
                    {
                        text: 'Start',
                        value: 'start_date'
                    },
                    {
                        text: 'End',
                        value: 'end_date'
                    }
                ],
                xxsMobileTableHeaders: [
                    {
                        text: 'Job Name',
                        value: 'name'
                    },
                    {
                        text: 'Status',
                        value: 'status'
                    }
                ],
                activeTasks: [],
                stats: {
                    symbol: '$',
                    amount: 0,
                    job: {
                        active: 0
                    },
                    service: {
                        provided: 0,
                        requested: 0
                    }
                },
                newsPosts: [],
                generalHomeController: null,
                newsPostController: null,
                activeTaskSearch: '',
                newsArticleSearch: '',
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true
            }
        },

        beforeMount () {
            this.generalHomeController = new GeneralHomeController()
            this.newsPostController = new NewsPostController()
        },

        async mounted () {
            await this.getActiveTasks()
            await this.getStats()
            await this.getNewsPosts()

            var self = this
        
            const newsPostContainer = document.getElementById('newsPostContainer')
            newsPostContainer.addEventListener('scroll', async () => {
                if (newsPostContainer.offsetHeight + newsPostContainer.scrollTop + 120 >= newsPostContainer.scrollHeight) {
                    if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                        await this.getNewsPosts()
                    }
                }
            })
        },

        methods: {
            async getActiveTasks () {
                try {
                    this.activeTasks = await this.generalHomeController.retrieveActiveTasks()

                    if (this.activeTasks && this.activeTasks.length > 0) {
                        for (let index = 0; index < this.activeTasks.length; index++) {
                            const item = this.activeTasks[index]

                            if (item.start_date) {
                                const removeTime = item.start_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.start_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            if (item.end_date) {
                                const removeTime = item.end_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.end_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }

                            if (item.milestone) {
                                const removeTime = item.milestone.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.milestone = splitConvertedDate[2] + ' ' + splitConvertedDate[1]
                            }
                        }
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getStats () {
                try {
                    this.stats = await this.generalHomeController.retrieveStats()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async getNewsPosts () {
                try {
                    this.isLoading = true

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    const recordsToRetrieve = 6

                    const response = await this.newsPostController.get(this.lastPageLoaded, recordsToRetrieve)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.expiration_date) {
                                const removeTime = item.expiration_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.expiration_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] +  ' ' + splitConvertedDate[3]
                            }

                            this.newsPosts.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const newsPostContainer = document.getElementById('newsPostContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && newsPostContainer.offsetHeight + newsPostContainer.scrollTop === newsPostContainer.scrollHeight) await this.getNewsPosts()
                    })
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            viewTask (item) {
                if (item.type === 'Job') {
                    // Pending
                    if (item.status.stage_id === 2) this.$router.push(`/job/${item.parent_id}/view`)
                    // Active
                    else if (item.status.stage_id === 3) this.$router.push(`/job/${item.parent_id}/overview/${item.child_id}`)
                } else if (item.type === 'Service') {
                    // Pending
                    if (item.status.stage_id === 2) this.$router.push(`/service/${item.parent_id}/view`)
                    // Active
                    else if (item.status.stage_id === 3) this.$router.push(`/service/${item.parent_id}/requested/${item.child_id}`)
                }
            }
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>
<style scoped>
    >>> .v-btn:not(.v-btn--round).v-size--default {
        min-width: 0;
    }

    >>> .v-list-item {
        min-height: 0;
        height: 30px;
    }

    >>> th {
        font-size: 15px !important;
        font-weight: 600 !important;
        color: #8F9BB3 !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> td {
        font-size: 12px !important;
        font-weight: normal !important;
        font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, Sans-Serif !important;
        height: 60px !important;
        padding: 0px !important;
        padding-left: 12px !important;
    }

    >>> .mobile-table th {
        padding-left: 4px !important;
    }

    >>> .mobile-table td {
        padding-left: 4px !important;
    }

    >>> thead {
        background-color: #EFF4FA !important;
    }

    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
