<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-21 14:04:58
 * @ Description: Component for displaying an overview of the day's calendar/schedule.
 -->

<template>
    <v-card
        flat
        class="rounded-lg"
        style="border: 1px solid #EFF4FA;"
        :height="expandedSchedule ? '378px' : '68px'"
        max-height="378px"
    >
        <v-card-text
            style="color: #2E567A;"
        >
            <v-row
                justify="center"
                style="max-height: 100%;"
            >
                <!-- Heading & Date -->
                <v-col
                    cols="8"
                    style="line-height: 1;"
                >
                    <div
                        class="os-13-r"
                        style="color: #707070; opacity: 0.6;"
                    >
                        {{ today }}
                    </div>
                    <div
                        class="os-17-sb"
                    >
                        Your Schedule
                    </div>
                </v-col>
                <!-- Heading & Date -->

                <!-- Add and Calendar Buttons -->
                <v-col
                    cols="4"
                    class="text-right"
                    :class="$store.state.general.customBreakpoints.xxs || $vuetify.breakpoint.md ? 'px-0' : ''"
                    align-self="center"
                >
                    <v-btn
                        icon
                        @click="calendar"
                    >
                        <v-icon
                            color="#2E567A"
                            size="20"
                        >
                            {{ mdiCalendarOutline }}
                        </v-icon>
                    </v-btn>
                    <!-- <v-btn
                        icon
                    >
                        <v-icon
                            color="#2E567A"
                            size="20"
                        >
                            {{ mdiPlus }}
                        </v-icon>
                    </v-btn> -->
                    <v-btn
                        icon
                        @click="expandedSchedule = !expandedSchedule"
                        v-if="$vuetify.breakpoint.smAndDown"
                    >
                        <v-icon
                            color="#2E567A"
                            size="20"
                            v-if="expandedSchedule"
                        >
                            {{ mdiChevronUp }}
                        </v-icon>
                        <v-icon
                            color="#2E567A"
                            size="20"
                            v-else
                        >
                            {{ mdiChevronDown }}
                        </v-icon>
                    </v-btn>
                </v-col>
                <!-- Add and Calendar Buttons -->

                <v-col
                    class="py-0"
                    v-if="expandedSchedule"
                >
                    <v-divider />
                </v-col>

                <!-- Events -->
                <v-col
                    cols="12"
                    style="max-height: calc(378px - 68px); overflow-y: auto;"
                    v-if="expandedSchedule"
                >
                    <div
                        v-if="events.length > 0"
                    >
                        <v-row
                            v-for="(item, index) in events"
                            :key="index"
                        >
                            <v-col
                                cols="4"
                                sm="3"
                                md="4"
                                lg="3"
                                class="mt-4 py-2"
                            >
                                <span
                                    class="os-17-sb"
                                >
                                    {{ item.date }}
                                </span>
                                <span
                                    class="os-17-r"
                                >
                                    {{ item.day }}
                                </span>
                            </v-col>
                            <v-col
                                cols="8"
                                sm="9"
                                md="8"
                                lg="9"
                            >
                                <div
                                    v-for="(event, eventIndex) in item.events"
                                    :key="eventIndex"
                                    :style="event.is_meeting ? 'background-color: #FFA858;' : 'background-color: #2E567A;'"
                                    style="color: white; cursor: pointer;"
                                    class="pa-2 my-2 rounded text-truncate"
                                    @click="goToEvent(event)"
                                >
                                    {{ event.summary }}
                                </div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                                v-if="(index + 1) !== events.length"
                            >
                                <v-divider />
                            </v-col>
                        </v-row>
                    </div>
                    <div
                        v-else
                        style="display: flex; justify-content: center; color: rgba(0, 0, 0, 0.38);"
                        class="os-12-r"
                    >
                        No events scheduled today
                    </div>
                </v-col>
                <!-- Events -->
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mdiCalendarOutline, mdiPlus, mdiChevronDown, mdiChevronUp } from '@mdi/js'
    import { GeneralCalendarController } from '@/logic/controller/index.controller.js'

    const moment = require('moment')

    export default {
        name: 'GeneralHomeYourScheduleComponent',

        data () {
            return {
                mdiCalendarOutline,
                mdiPlus,
                mdiChevronDown,
                mdiChevronUp,
                events: [],
                today: null,
                generalCalendarController: null,
                calendarEvents: [],
                expandedSchedule: true
            }
        },

        beforeMount () {
            this.generalCalendarController = new GeneralCalendarController()
        },

        async mounted () {
            var today = new Date()
            const splitToday = today.toString().split(' ')
            this.today = splitToday[2] + ' ' + splitToday[1] + ' ' + splitToday[3]

            const dateTimeFormat = "YYYY-MM-DD"
            const dateParam = moment(new Date()).format(dateTimeFormat)

            await this.getCalendarEvents(dateParam, dateParam)
        },

        methods: {
            calendar () {
                if (this.$route.path !== `/calendar`) this.$router.push(`/calendar`)
            },

            async getCalendarEvents (startDate, endDate) {
                try {
                    const response = await this.generalCalendarController.retrieve(startDate, endDate)

                    if (response.meeting_event && response.meeting_event.length > 0) {
                        for (let index = 0; index < response.meeting_event.length; index++) {
                            const event = response.meeting_event[index]
                            const group = event.start_date.split('.')[0]
                            group = group.split(' ')[0]
                            group = new Date(group)
                            group = group.toString().split(' ')
                            event.date = group[2]
                            event.day = group[0]
                            event.group = event.date + ' ' + event.day
                            this.calendarEvents.push(event)
                        }
                    }

                    if (response.day_event && response.day_event.length > 0) {
                        for (let index = 0; index < response.day_event.length; index++) {
                            const event = response.day_event[index]
                            const group = event.start_date.split('.')[0]
                            group = group.split(' ')[0]
                            group = new Date(group)
                            group = group.toString().split(' ')
                            event.date = group[2]
                            event.day = group[0]
                            event.group = event.date + ' ' + event.day
                            this.calendarEvents.push(event)
                        }
                    }

                    const groupByKey = (list, key) => list.reduce((hash, obj) => ({...hash, [obj[key]]:( hash[obj[key]] || [] ).concat(obj)}), {})
                    this.calendarEvents = groupByKey(this.calendarEvents, 'group')

                    const groupedEvents = []

                    for (let key in this.calendarEvents) {
                        if (this.calendarEvents[key] && this.calendarEvents[key].length > 0) {
                            const groupedEventObject = {
                                date: this.calendarEvents[key][0].date,
                                day: this.calendarEvents[key][0].day,
                                events: this.calendarEvents[key]
                            }
                            groupedEvents.push(groupedEventObject)
                        }
                        this.events = groupedEvents
                    }
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            goToEvent (event) {
                if (event.is_meeting) this.$router.push('/calendar')
                else if (event.work_type_id === 3 && event.work_id) this.$router.push(`/job/${event.work_id}/view`)
            }
        }
    }
</script>